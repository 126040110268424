import * as React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import "./layout.css"
import Header from "./Header"

import styled from "@emotion/styled"
import Footer from "./Footer"
import MobileFooter from "./Footer/MobileFooter"
import Fab from "@mui/material/Fab"
import { useLocation } from "@reach/router"
import { Typography } from "@mui/material"
import { Color } from "../util/Color"

const Layout = ({ children }) => {
  const location = useLocation()
  const [view, setView] = React.useState("PC")

  React.useEffect(() => {
    const w = window.innerWidth
    setView(
      (() => {
        if (1440 < w) return "PC"
        else if (960 < w) return "TABLET"
        else return "MOBILE"
      })()
    )
  })

  const getFooter = () => {
    if (view === "MOBILE") {
      return <MobileFooter />
    }
    return <Footer />
  }

  return (
    <main>
      <Header />
      <Contents>{children}</Contents>
      {view === "MOBILE" &&
        location.pathname.includes("/contact") === false && (
          <SFab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/contact")}
          >
            <Wrapper>
              <span className="material-symbols-outlined">send</span>
              <STypography variant="h5">Contact</STypography>
            </Wrapper>
          </SFab>
        )}
      {getFooter()}
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Contents = styled("div")(({ view }) => ({
  marginTop: "20px",
  marginBottom: "80px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

const STypography = styled(Typography)(({ view }) => ({
  fontFamily: "Roboto",
  fontFeatureSettings: '"palt" 1',
  fontSize: "8px",
  fontWeight: 700,
  marginTop: "-8px",
}))

const Wrapper = styled("div")({})
const SFab = styled(Fab)({
  position: "fixed",
  right: "24px",
  bottom: "24px",
  width: "80px",
  height: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `${Color.orange} !important`,
})
