import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Color, addAlpha } from '../util/Color'

import styled from "@emotion/styled"
import { Divider, Typography } from "@mui/material"
import { Link } from "gatsby"
import data from "../datas/news.json"

import CenterTitle from "../components/feature/Home/CenterTitle"
const description = ''

const News = () => {

  const [view, setView] = React.useState('PC')

  React.useEffect(() => {
    const w = window.innerWidth
    setView((() => {
      if (1440 < w) return "PC"
      else if (960 < w) return "TABLET"
      else return "MOBILE"
    })())
  })

  return (
    <Layout>
      <Seo title="News" description={description}/>
      {view === 'MOBILE' ? <CenterTitle en="NEWS" ja="お知らせ"/> : <Margin margin="129"/>}
      <Body view={view}>
        {data.map((d, i) => {
          return (
            <Wrapper view={view} key={i}>
              <Content view={view}>
                <Row>
                <TextArea variant="h6" view={view}>
                  {d.title}
                </TextArea>
                {!!d.link && view !== 'MOBILE' && <Link to={d.link} target="_brank"><span className="material-icons">open_in_new</span></Link>}
                </Row>
                <Info view={view}>
                  <Wrap>
                    <STypography variant={view === 'PC' ? "subtitle2" : "caption"} sx={{marginRight: '24px'}}>{d.date}</STypography>
                    <STypography variant={view === 'PC' ? "subtitle2" : "caption"}>{d.type}</STypography>
                  </Wrap>
                  {!!d.link && view === 'MOBILE' && <SLink to={d.link} target="_brank"><Icon className="material-icons">open_in_new</Icon></SLink>}
                </Info>
              </Content>
              <SDivider view={view}/>
            </Wrapper>
          )
        })}
      </Body>
    </Layout>
  )
}

export default News

const Icon = styled('span')({
  height: '24px'
})
const SLink = styled(Link)({
  height: '24px'
})
const STypography = styled(Typography)({
  fontWeight: 700,
  fontFamily: 'Noto Sans JP'
})

const TextArea = styled(Typography)(({view}) => ({
  fontWeight: 700,
  fontFamily: 'Noto Sans JP',
  fontSize: view  === 'PC' ? '20px' : view === 'TABLET' ? '16px' : '14px',
}))

const Body = styled('div')(({view}) => ({
  margin: view === 'MOBILE' ? '0px 8px' : '0px 15vw',
  color: Color.black,
  paddingRight: '32px',
  width: view === 'MOBILE' ? 'calc(100vw - 32px)' : '70vw',
  height: '640px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  overflowY: 'scroll',
  '::-webkit-scrollbar-track': {
    background: addAlpha(Color.black, 0.42),
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '2px',
    background: addAlpha(Color.orange, 0.9),
  },
  '::-webkit-scrollbar': {
    width: '2px',
    borderRadius: '4px',
  },
}))

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

const Margin = styled('div')(({margin}) => ({
  height: margin + 'px',
  width: '1px',
}))

const Wrapper = styled('div')(({view}) => ({
  marginTop: view === 'MOBILE' ? '16px' : '32px',
  width: '100%'
}))
const Info = styled('div')(({view}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: view === 'MOBILE' ? '8px' : '16px'
}))

const SDivider = styled(Divider)(({view}) => ({
  height: '1px',
  width: '100%',
  background: Color.orange,
  border: 'none',
  margin: view === 'MOBILE' ? '16px 0px 0px' : '32px 0px 0px'
}))
const Content = styled('div')(({view}) => ({
  margin: view === 'MOBILE' ? '0px 8px' : '0px 16px'
}))

const Date = styled('div')({
  marginRight: '24px'
})
const Wrap = styled('div')({
  display: 'flex',
})
